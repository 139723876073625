.c-header {
	@include clearfix();
	position: fixed;
	z-index: 10;
	width: 100%;
	height: 85px;
	background: #fff;
	border-bottom: 1px solid #eee;

	img {
		height: 150px;
	}

	&__logo {
		padding: 15px;
		background: $color-primary;
		max-width: 230px;
		display: inline-block;

		@include media('>375px') {
			margin: 0 0 0 30px;
		}
		@include media('>480px') {
			margin: 0 0 0 60px;
		}
		@include media('>900px') {
			float: left;
		}
	}

	&__nav {
		@include media('>900px') {
			float: right;
		}
		@include media('>960px') {
			padding: 0 20px;
		}

		&-toggle {
			position: absolute;
			top: 30px;
			right: 10%;
			z-index: 999;
			cursor: pointer;

			@include media('>900px') {
				display: none;
			}
		}
	}

	&--sticky {
		img {
			height: 75px;
		}
	}
}
