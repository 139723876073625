.o-grid {
	@include media('>grid') {
		display: flex;
	}

	&__item50 {
		flex: 1 1;
		margin-bottom: 60px;

		@include media('>grid') {
			margin-right: gutter();
			margin-bottom: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__item30 {
		flex: 1 1 span(4);
		margin-bottom: 60px;

		@include media('>grid') {
			margin-right: gutter();
			margin-bottom: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}




