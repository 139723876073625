.c-footer {
	padding: 255px 0 30px;
	position: relative;
	overflow: hidden;

	&__heading {
		background: $color-primary;
		color: #fff;
		text-align: center;
		padding: 30px 0;
		position: relative;
		z-index: 5;

		h2 {
			margin: 0;
		}
	}

	&__copyright {
		@extend .o-wrapper;
		padding-top: 20px;
	}
}
