/* === Images === */
img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}


img[width],
img[height] {
	max-width: none;
}
