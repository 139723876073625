.c-navigation {
	@extend %list-reset;
	display: none;

	@include media('<640px') {
		position: absolute;
		z-index: 999;
		width: 100%;
		background: #fff;
	}

	@include media('>900px') {
		display: inline-block;
		background: none;
	}

	&__item {
		position: relative;
		text-align: center;

		@include media('>900px') {
			display: inline-block;
			text-align: left;
		}
	}

	&__item-level1 {
		display: block;
		position: relative;
		font-size: 18px;
		padding: 31px 12px 30px;

		@include media('>1100px') {
			text-transform: uppercase;
		}
		@include media('>1155px') {
			padding: 31px 22px 30px;
		}

		&:link, &:visited {
			color: $color-text;
		}

		&:hover, &.hover,
		&--active:link, &--active:visited {
			background: color('primary');
			color: #fff;
		}

	}
}
