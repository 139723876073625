//------------------------------------//
//  $VARIABLES
//------------------------------------//


// Fonts
$font-family-sans             	: 'Lato', sans-serif;
$font-family-serif            	: Georgia, "Times New Roman", Times, serif;
$font-family-mono             	: Menlo, Monaco, "Courier New", monospace;


// Base
$base-font-size					: 16px;
$base-line-height				: 24px;
$base-font-size--mobile			: $base-font-size;
$base-font-weight				: 300;
$base-font-weight-bold			: bold;

$base-font-family             	: $font-family-sans;
$base-font-family-alt         	: $font-family-serif;

$font-family-headings         	: $base-font-family; // using inherit will use $font-family-base for all headings
$font-weight-headings         	: 300;    // instead of browser default, bold
$line-height-headings         	: 1.1;

$type-largest					: 40px;
$type-large						: 30px;
$type-mid						: 22px;
$type-base						: $base-font-size;
$type-small						: 12px;


// Calculated measurements
$line-height-ratio: $base-line-height / $base-font-size;
$base-spacing-unit: $line-height-ratio;
$half-spacing-unit: $base-spacing-unit / 2;
